import React from 'react';
import SingleService from '../../../components/SingleService/SingleService';

const HomeServices = () => {
   return (
      <>
         <section className="services__area pt-115 pb-80">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center mb-90 wow fadeInUp" data-wow-delay=".2s">
                        <span>Services that we provide</span>
                        <h2>Provide awesome softwer solutions service with our products.</h2>
                     </div>
                  </div>
               </div>
               <div className="row">

                  <SingleService icon="1" 
                    title="Custom Web Application" 
                  desc="We develop custom software solution to automate your business operations according to your requirements." 
                />
                  <SingleService icon="2" 
                  title="Custom Mobile Application" 
                  desc="We develop android and ios app as prer your business requirment." 
                  />
                  <SingleService icon="3" 
                  desc="We develop custom desktop application for your business operations according to your requirements. " 
                  title="Custom Desktop Application" />

                  <SingleService icon="4" 
                  desc="We present your data in beutifull Power Bi Dashboard with your data in excel or database formate." 
                  title="PowerBi Dashboard" />

                  <SingleService icon="5" 
                  desc="Present your skills throught your portfolio website with our beutifull portfolio serverice. We create all type of portfolio website like for Merchandiser, Lawyer, Contractor" 
                  title="Portfolio Website" />
                  <SingleService icon="6" 
                  desc="We build professional website for your business institute. We build website for Insurance company, Logistic company, Security company etc." 
                  title="Business Website" />

                  <SingleService icon="7"
                  desc="We build professional website for your organization.; Like We build website for School and madrasha, NGO, Hospital and Digonostic  etc."  
                  title="Organization Website" />

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;