import React from 'react';
import { Link } from 'react-router-dom';
import {MdEmail} from 'react-icons/md'
import {BsMessenger} from 'react-icons/bs'
import {IoLogoWhatsapp} from 'react-icons/io'

const SIngleContactInfo = ({image,title,btn_text,desc}) => {
   return (
      <>
         <div className="col-xl-5 col-lg-6 col-md-6 offset-xl-1">
            <div className="contact__help-item white-bg text-center mb-30 wow fadeInLeft" data-wow-delay=".3s">
               <div className="contact__icon mb-35">
                  <img src={`assets/img/icon/contact/${image}.png`} alt="" />
               </div>
               <div className="contact__text">
                  <h3>{title}</h3>
                  <p>{desc}</p>
                  <a href="http://m.me/hasan.jafor" target="_blank" rel="noopener noreferrer"><BsMessenger fontSize="25"/></a>
          <a href="https://wa.me/+8801736659047" target="_blank" rel="noopener noreferrer"><IoLogoWhatsapp fontSize="25"/></a>
          <a href="mailto:support@hashtechltd.com" target="_blank" rel="noopener noreferrer"><MdEmail fontSize="25"/></a>

                  {/* <Link to="/contact" className="z-btn z-btn-border">{btn_text}</Link> */}
               </div>
            </div>
         </div>
      </>
   );
};

export default SIngleContactInfo;