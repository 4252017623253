import { CgArrowLongRight } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SideImageWithDesc = ({ title, desc, items=[], imgSrc, imgPosition }) => {
   console.log(items);
   return (
      <>
         <section className="about__area pb-200 pt-100">
            <div className="container">
               <div className="row">
                  {
                     imgPosition == "left" ? <>
                        <div className="col-xl-6 pr-0 col-lg-6">
                           <div className="m-img">
                              <img src={imgSrc} alt="" />
                              <div className="about__shape">
                                 <img src="assets/img/about/red-shape.png" alt="" />
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                           <div className="about__content">
                              <div className="section__title section__title-3 mb-25">
                                 <h2>{title}</h2>
                              </div>
                              <p>{desc}</p>
                              <div className="about__list">
                                 <ul>
                                    {
                                       items.map( (item) => {
                                         return <li ><span><i > <FaCheck /> </i> {item}</span>
                                          </li>
                                       })
                                    }
                                 </ul>
                              </div>
                              <Link to="/services" className="z-btn " >Explore More<i > <CgArrowLongRight /> </i></Link>
                           </div>
                        </div>
                     </>
                        :
                        <>
                           <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                           <div className="about__content">
                              <div className="section__title section__title-3 mb-25">
                                 <h2>{title}</h2>
                              </div>
                              <p>{desc}</p>
                              <div className="about__list">
                                 <ul>
                                    {
                                       items.map( (item) => {
                                         return <li ><span><i > <FaCheck /> </i> {item}</span>
                                          </li>
                                       })
                                    }
                                 </ul>
                              </div>
                              <Link to="/services" className="z-btn " >Explore More<i > <CgArrowLongRight /> </i></Link>
                           </div>
                           </div>
                           <div className="col-xl-6 pr-0 col-lg-6">
                              <div className="m-img">
                                 <img src={imgSrc} alt="" />
                                 <div className="about__shape">
                                    <img src="assets/img/about/red-shape.png" alt="" />
                                 </div>
                              </div>
                           </div>
                        </>
                  }

               </div>
            </div>
         </section>
      </>
   );
};

export default SideImageWithDesc;