import React from 'react';
import './HomeTechnologyWeUse.css'
const HomeTechnologyWeUse = () => {
   return (
      <>
         <section className="techWeUse__area pb-200 pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className='tech__we_use_img m-img'>
                        <img src="assets/img/tech-we-use/Technologies-We-Use.svg" alt="" />
                        </div> 
                        
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="techWeUse__content">
                        <div className="section__title section__title-3 mb-25">
                           <span>Technologies</span>
                           <h2>Technologies We Use to Build Awesome Products</h2>
                        </div>
                        <p>At HashTech, we follow the Agile Software Development methodology, which refers to a group of software development methodologies that are based on similar principles.</p>
                       
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTechnologyWeUse;