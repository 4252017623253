import React from 'react';
import { BiLock } from 'react-icons/bi';
import { AiFillAndroid } from "react-icons/ai";
import { CgArrowLongRight } from 'react-icons/cg';
import { FaLightbulb, FaDesktop } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import HomeTwoSingleFeature from '../../../components/HomeTwoSingleFeature/HomeTwoSingleFeature';

const HomeTwoFeatures = () => {
   return (
      <>
         <section className="features__area pt-115 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="features__content-left">
                        <div className="section__title section__title-3 mb-25">
                           <span>Why we are?</span>
                           <h2>We are fully dedicated team to success your project</h2>
                        </div>
                        <p>We are more than a generic agency. Explore our Services and our Solutions for your business.</p>
                        <Link to="/about" className="z-btn">What we do<i><CgArrowLongRight/></i></Link>
                     </div>
                  </div>
                  <div className="col-xl-6 offset-xl-1 col-lg-6">
                     <div className="features__content-right">
                        <div className="row">
                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                              <HomeTwoSingleFeature icon={<FaLightbulb />} title="AI Development Team" desc="We always try to develop artificial intelligence feature up on demand with Open Ai model and tenser flow . "/>
                              <HomeTwoSingleFeature icon={<IoDocumentTextOutline />} title="Web Application" desc="We build web application for all of your business requirment." />

                           </div>

                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                              <HomeTwoSingleFeature icon={<AiFillAndroid />} title="Mobile Application" desc="We build mobile application for all of your business requirment."/>
                              <HomeTwoSingleFeature icon={<FaDesktop />} title="Desktop Application" desc="We build mobile application for all of your business requirment."/>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoFeatures;