import React from 'react';
import HomeHeader from './HomeHeader/HomeHeader';
import HomeHeroSlider from './HomeHeroArea/HomeHeroSlider';
import HomeTwoFeatures from '../HomeTwo/HomeTwoFeatures/HomeTwoFeatures';
import HomeThreeProjects from '../HomeThree/HomeThreeProjects/HomeThreeProjects';

import HomeAbout from './HomeAbout/HomeAbout';
import HomeCapabilities from './HomeCapabilities/HomeCapabilities';
import HomeServices from './HomeServices/HomeServices';
import HomeCounterArea from './HomeCounterArea/HomeCounterArea';
import HomeTeams from './HomeTeams/HomeTeams';
import HomePricing from './HomePricing/HomePricing';
import HomeBrands from './HomeBrands/HomeBrands';
import HomeContact from './HomeContact/HomeContact';
import Footer from '../../components/shared/Footer';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeTechnologyWeUse from './HomeTechnologyWeUse/HomeTechnologyWeUse';
import HomeTwoFaq from '../HomeTwo/HomeTwoFaq/HomeTwoFaq';


const Home = () => {
    return (
        <>
            <PageHelmet pageTitle="Home Page" />

            <HomeHeader/>
            <HomeHeroSlider/>
            <HomeTwoFeatures/>
            <HomeThreeProjects/>
            <HomeAbout/>
            <HomeCapabilities/>
            <HomeServices/>
            <HomeTechnologyWeUse/>
            <HomeTwoFaq/>
            <CommonCtaArea/>
            {/* <HomeBrands/> */}
            {/* <HomeContact/> */}
            <Footer/>
        </>
    );
};

export default Home;