import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import SideImageWithDesc from '../../components/SideImageWithDesc/SideImageWithDesc';
import HomeHeader from '../Home/HomeHeader/HomeHeader';

const Portfolio = () => {
   
   return (
      <>
         <PageHelmet pageTitle="Portfolio Page" />

         <HomeHeader />
         <CommonPageHeader title="Products that make value for business" subtitle="Product" />
         <SideImageWithDesc
            title="Hostel Management System (HMS)"
            desc="Explore our very usefull HMS tools that automate your Hostel Business activity like:"
            items={ [
               "Room Allocation To The Hostel Members",
               "Manage The Hostel Members Financial Records",
               "Visitors Management In The Hostel Management System",
               "Manage Daily Meal",
               "Track All Expenses",
               "Income management",
               "Various reports",
               "Admin,Employee,Member access panel",
               "Free  hostel website"
            ] }  
            imgSrc="assets/img/hms/hostel2.png"
            imgPosition="left"
         />
         <SideImageWithDesc
            title="Purchase Inventory with Accounting (PIA)"
            desc="Explore our very usefull PIA tools to manage your business with inventory,purchase and sales. With (PIA) you can manage"
            items={[
               "Manage Warehouse With Stock",
               "Manage Purchase and Accounts Payable",
               "Sales and Accounts Receivable",
               "Manage Expense",
               "View Transaction Reports",
               "Stock Reports",
               "Party Reports"
            ]}
            imgSrc="assets/img/pia/pia.jpg"
            imgPosition="right"
         />
         <SideImageWithDesc
            title="Bangla Card"
            desc="Make your functional card with our system with more than 1000+ template and element"
            items={
               [
                  "You can choose various template like: Weading, Birthday card",
                  "You can make your card with various element",
                  "You can download you card free and share  to your friend"
               ]
            }
            imgSrc="assets/img/banglaCard/poster.png"
            imgPosition="left"
         />
         <Footer />
      </>
   );
};

export default Portfolio;