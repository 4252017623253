import React from 'react';

const HomeTwoFaq = () => {
   return (
      <>
         <section className="faq__area p-relative pt-135 pb-120 grey-bg-12">
            {/* <div className="faq__thumb" style={{ background: `url(assets/img/faq/faq-1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div> */}
            <div className="container">
               <div className="row">
                  <div className="col-xl-12 offset-xl-12 col-lg-12 offset-lg-12 col-md-12 offset-md-12">
                     <div className="faq__wrapper">
                        <div className="section__title section__title-3 mb-25 wow fadeInUp" data-wow-delay=".2s">
                           <span>FAQ</span>
                           <h2>know the commonly<br /> asked question</h2>
                        </div>

                        <div className="accordion" id="accordionExample">

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingOne">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       Why should I consider your software development company as a partner?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    At #Tech, we take pride in developing high-quality applications in record time. We have  experienced developers who understand the importance of agile methodologies and use cutting-edge technologies such as C#, NodeJS, Angular and React Native to deliver unique software solutions that provide maximum value for our clients. Our team is committed to delivering timely results while keeping costs low so you can maximize your ROI.
                                 </div>
                              </div>
                           </div>

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingTwo">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                       Do you offer custom software development services for my industry?
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    We understand the need for reliable, secure and cost-effective software solutions that are designed to meet the specific requirements of each client. Our developers have a wealth of expertise in developing custom applications and can tailor our services to the exact needs of your business. From enterprise resource planning (ERP) systems to customer relationship management (CRM) platforms, we offer comprehensive software development solutions that will help optimize your operations and improve efficiency.
                                 </div>
                              </div>
                           </div>

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingThree">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                       What is your process for end-to-end software development?
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    #Tech follows a tried and tested process for end-to-end software development. This process includes gathering requirements, designing a solution, developing and testing the software, and deploying and maintaining the finished product. Our team of experienced software engineers will work with you at every step of the way to ensure that your project is a success.
                                 </div>
                              </div>
                           </div>
                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingFoure">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFoure" aria-expanded="false" aria-controls="collapseFoure">
                                       Do you offer teams for mobile app development?
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseFoure" className="accordion-collapse collapse" aria-labelledby="headingFoure" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    Yes, #Tech offers team augmentation services for mobile app development. Our team of experienced software engineers has expertise in developing high-quality, user-friendly mobile apps for a variety of platforms, including iOS, Android, and Windows. Our team members will work closely with your team to ensure that the mobile app development process goes smoothly and that the final product meets your requirements.
                                 </div>
                              </div>
                           </div>
                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingFive">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                       What is your preferred development methodology?
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    We have experience with different methodologies. We can adapt our approach as needed to meet the specific requirements of our clients.

                                    In most of our software development projects, we follow the agile Scrum methodology. It allows for rapid iteration and continuous delivery and is particularly useful for projects where requirements are subject to change.

                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoFaq;